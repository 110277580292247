import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Followed these instructions: https://juristr.com/blog/2018/01/ng-app-runtime-config/

// In order to use this, inject the EnvConfigService in the contructor and call getConfig

@Injectable()
export class EnvConfigService {
  private envConfig;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return this.http.get('/assets/envConfig/current.json')
      .toPromise()
      .then(data => {
        this.envConfig = data;
      });
  }

  getConfig() {
    return this.envConfig;
  }
}
