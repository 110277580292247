import { Injectable } from '@angular/core';
import { EnvConfigService } from './env-config.service';
import { AuthConfig } from 'angular-oauth2-oidc';

@Injectable()
export class AuthConfigService {

  constructor(private envConfig: EnvConfigService) { }

  getConfig(): AuthConfig {
    return <AuthConfig>this.envConfig.getConfig().oauth;
  }
}
