import { Component, OnInit, OnDestroy } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { AuthConfigService } from './services/auth-config.service';
import { GlobalVars } from './global-vars';
import { UserService } from './services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  // https://stackoverflow.com/questions/38008334/angular-rxjs-when-should-i-unsubscribe-from-subscription
  private ngUnsubscribe = new Subject(); // to prevent memory leaks


  constructor(public oauthService: OAuthService,
              private authConfigService: AuthConfigService,
              private userService: UserService,
              private globalvars: GlobalVars) {

    this.oauthService.configure(this.authConfigService.getConfig());
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService
    .loadDiscoveryDocumentAndTryLogin()
    .then(_ => {
      if (this.oauthService.hasValidAccessToken() && !this.globalvars.loginUser) {
        this.userService.getIdentity()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          data => {
            if (JSON.stringify(data) === JSON.stringify({})) {
              console.warn('Whoami returned empty object. This user does not even have the permission to see it\'s own data');
            }
            this.globalvars.loginUser = this.userService.jsonToUser(data);
            console.log('whoami fertig');
            this.globalvars.setAppInitialized();
          },
          error => {
            console.log('error occured while performing whoami');
          });
      } else {
        // whoami won't be loaded (either becaue not logged in or because it's still there)
        this.globalvars.setAppInitialized();
      }
    });
  }



  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
