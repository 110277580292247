export const TRANSLATION_DE = '<?xml version="1.0" encoding="UTF-8"?>\n' +
'<xliff version="1.2" xmlns="urn:oasis:names:tc:xliff:document:1.2">\n' +
'  <file source-language="en" datatype="plaintext" original="ng2.template" target-language="de">\n' +
'    <body>\n' +
'      \n' +
'      \n' +
'      \n' +
'      \n' +
'      <trans-unit id="0082b91258b7aabdae9410aaeed680e97ad11711" datatype="html">\n' +
'        <source><x id="INTERPOLATION"/> <x id="INTERPOLATION_1"/> Details !</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-deail\\user-deail.component.ts</context>\n' +
'          <context context-type="linenumber">6</context>\n' +
'        </context-group>\n' +
'      <target state="translated"><x id="INTERPOLATION"/> <x id="INTERPOLATION_1"/> Eigenschaft !</target>\n' +
'	  </trans-unit>\n' +
'	  \n' +
'	  \n' +
'	  \n' +
'      <trans-unit id="112b236986d0d3c0e755d5e2d6833eb821f477bf" datatype="html">\n' +
'        <source>User ID: <x id="INTERPOLATION"/></source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-deail\\user-deail.component.ts</context>\n' +
'          <context context-type="linenumber">9</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Benutzer ID: <x id="INTERPOLATION"/></target></trans-unit>\n' +
'      \n' +
'	  \n' +
'	  <trans-unit id="616e206cb4f25bd5885fc35925365e43cf5fb929" datatype="html">\n' +
'        <source>Name:</source>\n' +
'        <target state="translated">Name:</target>\n' +
'	  \n' +
'	  <context-group purpose="location">\n' +
'	  <context context-type="sourcefile">app\\components\\user-deail\\user-deail.component.ts</context>\n' +
'	  <context context-type="linenumber">12</context>\n' +
'	  </context-group>\n' +
'	  <context-group purpose="location">\n' +
'	  <context context-type="sourcefile">app\\components\\group-new\\group-new.component.ts</context>\n' +
'	  <context context-type="linenumber">14</context>\n' +
'	  </context-group>\n' +
'	  <context-group purpose="location">\n' +
'	  <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'	  <context context-type="linenumber">25</context>\n' +
'	  </context-group>\n' +
'	  </trans-unit>\n' +
'	  \n' +
'	  \n' +
'      <trans-unit id="4c28790e19aaa7438cee2e63983a12fa31ba2a3d" datatype="html">\n' +
'        <source>Surname:</source>\n' +
'        \n' +
'        \n' +
'      <target state="translated">Nachname:</target>\n' +
'<context-group purpose="location">\n' +
'<context context-type="sourcefile">app\\components\\user-deail\\user-deail.component.ts</context>\n' +
'<context context-type="linenumber">18</context></context-group>\n' +
'<context-group purpose="location">\n' +
'<context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'<context context-type="linenumber">33</context></context-group></trans-unit>\n' +
'      <trans-unit id="91b96d7228b6e05c238fa4e39abfc17ad51a6eef" datatype="html">\n' +
'        <source>Email:</source>\n' +
'        \n' +
'        \n' +
'      <target state="translated">E-mail:</target>\n' +
'<context-group purpose="location">\n' +
'<context context-type="sourcefile">app\\components\\user-deail\\user-deail.component.ts</context>\n' +
'<context context-type="linenumber">24</context></context-group>\n' +
'<context-group purpose="location">\n' +
'<context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'<context context-type="linenumber">41</context></context-group></trans-unit>\n' +
'      <trans-unit id="2aec761817235539aa90603685d1dc048c4ce0dc" datatype="html">\n' +
'        <source>Age:</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-deail\\user-deail.component.ts</context>\n' +
'          <context context-type="linenumber">30</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Alter:</target></trans-unit>\n' +
'      <trans-unit id="30fdc30d8b6510ea8f5821238dafc9a7c4f875e0" datatype="html">\n' +
'        <source><x id="START_ITALIC_TEXT" ctype="x-i"/><x id="CLOSE_ITALIC_TEXT" ctype="x-i"/> Save</source>\n' +
'        \n' +
'        \n' +
'        \n' +
'      <target state="translated"><x id="START_ITALIC_TEXT" ctype="x-i"/><x id="CLOSE_ITALIC_TEXT" ctype="x-i"/> Speichen</target>\n' +
'<context-group purpose="location">\n' +
'<context context-type="sourcefile">app\\components\\user-deail\\user-deail.component.ts</context>\n' +
'<context context-type="linenumber">38</context></context-group></trans-unit>\n' +
'      <trans-unit id="cbe437fd5a7c71f02d2c021a6f126a74721b4035" datatype="html">\n' +
'        <source><x id="START_ITALIC_TEXT" ctype="x-i"/><x id="CLOSE_ITALIC_TEXT" ctype="x-i"/> Back</source>\n' +
'        \n' +
'        \n' +
'        \n' +
'        \n' +
'      <target state="translated"><x id="START_ITALIC_TEXT" ctype="x-i"/><x id="CLOSE_ITALIC_TEXT" ctype="x-i"/> Zurück</target>\n' +
'<context-group purpose="location">\n' +
'<context context-type="sourcefile">app\\components\\user-deail\\user-deail.component.ts</context>\n' +
'<context context-type="linenumber">41</context></context-group></trans-unit>\n' +
'      \n' +
'      \n' +
'      \n' +
'      \n' +
'      \n' +
'      \n' +
'      \n' +
'      <trans-unit id="16c010b8ce99f879fa221b9949429ed2053c87e8" datatype="html">\n' +
'        <source>Group:</source>\n' +
'        \n' +
'      <target state="translated">Gruppen:</target>\n' +
'<context-group purpose="location">\n' +
'<context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'<context context-type="linenumber">119</context></context-group></trans-unit>\n' +
'      \n' +
'    <trans-unit id="848e86a3d39f96b59dcefe6defffbf95737ea6fa" datatype="html">\n' +
'        <source>\n' +
'        User List\n' +
'      </source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user\\user-list.component.ts</context>\n' +
'          <context context-type="linenumber">29</context>\n' +
'        </context-group>\n' +
'      <target state="translated">\n' +
'        Benutzerlist\n' +
'      </target></trans-unit>\n' +
'<trans-unit id="2b6b237ba64b406cfb9c3c1066d9ecca409f84b5" datatype="html">\n' +
'        <source>\n' +
'      Group List\n' +
'    </source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group\\group-list.component.ts</context>\n' +
'          <context context-type="linenumber">41</context>\n' +
'        </context-group>\n' +
'      <target state="translated">\n' +
'      Gruppenlist\n' +
'    </target></trans-unit>\n' +
'<trans-unit id="36ff2a1b6ca54317f45d08059b64a6d7c1ea6db7" datatype="html">\n' +
'        <source>\n' +
'        <x id="INTERPOLATION"/> details\n' +
'      </source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">5</context>\n' +
'        </context-group>\n' +
'      <target state="translated">\n' +
'        <x id="INTERPOLATION"/> Eigenschaft\n' +
'      </target></trans-unit>\n' +
'<trans-unit id="cda31dbd724cf5f4fa7a4274d9120651490c8a8c" datatype="html">\n' +
'        <source>Back</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">9</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">12</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">199</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Zurück</target>\n' +
'	  </trans-unit>\n' +
'<trans-unit id="de31685e1ee2f51b2d31b376653ad4a7e5406104" datatype="html">\n' +
'        <source>Group Name</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">16</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Gruppenname</target></trans-unit>\n' +
'<trans-unit id="9d53d8e1956abdb1196991271f1b8cb1649da84f" datatype="html">\n' +
'        <source>Add Member</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">20</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Gruppenmitglied hinzufügen</target></trans-unit>\n' +
'<trans-unit id="4d13a9cd5ed3dcee0eab22cb25198d43886942be" datatype="html">\n' +
'        <source>Users</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">25</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Benutzer</target></trans-unit>\n' +
'<trans-unit id="52c9a103b812f258bcddc3d90a6e3f46871d25fe" datatype="html">\n' +
'        <source>Save</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">28</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">61</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">182</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Speichen</target></trans-unit>\n' +
'<trans-unit id="80fa90ce67552f3f8a824f756a94327e41d3fc49" datatype="html">\n' +
'        <source>Members</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">32</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Gruppenmitglied</target></trans-unit>\n' +
'<trans-unit id="3e62ba4d9707fc71084f9dba63aa408b05e69d20" datatype="html">\n' +
'        <source>Delete Member</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">33</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Gruppenmitglied löschen</target></trans-unit>\n' +
'<trans-unit id="826b25211922a1b46436589233cb6f1a163d89b7" datatype="html">\n' +
'        <source>Delete</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">43</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\group-details\\group-details.component.ts</context>\n' +
'          <context context-type="linenumber">64</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Löschen</target>\n' +
'	  </trans-unit>\n' +
'<trans-unit id="17efbafb262c8827851f772dadb915c904e3ca0a" datatype="html">\n' +
'        <source><x id="INTERPOLATION"/> Details</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">10</context>\n' +
'        </context-group>\n' +
'      <target state="translated"><x id="INTERPOLATION"/> Eigenschaft</target></trans-unit>\n' +
'<trans-unit id="bd8884c2617d29ba7a56c0be2c9a4e6d9fdf8ee4" datatype="html">\n' +
'        <source>User ID:</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">20</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Benutzer-ID:</target></trans-unit>\n' +
'<trans-unit id="6fc2c1a8969bb0645d798d2066d264882d206fcc" datatype="html">\n' +
'        <source>name</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">29</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Name</target></trans-unit>\n' +
'<trans-unit id="731a8db26351bad1fd922d93295174517a4790f5" datatype="html">\n' +
'        <source>surname</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">37</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Nachname</target></trans-unit>\n' +
'<trans-unit id="240c9c1e96dc38814922a83ad041dc815b084f32" datatype="html">\n' +
'        <source>type</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">47</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">59</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">87</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">100</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Typ</target></trans-unit>\n' +
'<trans-unit id="0b15bf6fa1e5bdabc9fde97a14bee63a5d5e62f6" datatype="html">\n' +
'        <source>email</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">51</context>\n' +
'        </context-group>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">62</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Email</target></trans-unit>\n' +
'<trans-unit id="c7d761ff7a5f7e7a8f6d3899d4a7fe62e73afab3" datatype="html">\n' +
'        <source>cancel</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">73</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Abbrechen</target></trans-unit>\n' +
'<trans-unit id="d938d9f8530928e981811cabd72b0c58a8bc8171" datatype="html">\n' +
'        <source>number</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">91</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Nummer</target></trans-unit>\n' +
'<trans-unit id="fbcb0d75afbe6aec04269381598a737f4bd19db7" datatype="html">\n' +
'        <source>Number</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">103</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Nummer</target></trans-unit>\n' +
'<trans-unit id="fe85c4fbbaf58250de4a57e8595dbc9bafe8713b" datatype="html">\n' +
'        <source>Role:</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">136</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Rolle:</target></trans-unit>\n' +
'<trans-unit id="b4a0bf59944eeabcb65e19f512620c16ac91bb5c" datatype="html">\n' +
'        <source>User Details</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">197</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Benutzer-Eigenschaft</target></trans-unit>\n' +
'<trans-unit id="34c2f08a9d01c75af7da02ac21b1e5183901deca" datatype="html">\n' +
'        <source>Permission denied.</source>\n' +
'        <context-group purpose="location">\n' +
'          <context context-type="sourcefile">app\\components\\user-details\\user-details.component.ts</context>\n' +
'          <context context-type="linenumber">203</context>\n' +
'        </context-group>\n' +
'      <target state="translated">Erlaubniss nicht erteilt.</target></trans-unit></body>\n' +
'  </file>\n' +
'</xliff>';
