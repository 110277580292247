import { Injectable } from '@angular/core';
import { User } from './class/user';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from '../../node_modules/rxjs';

// followed this guide: https://stackoverflow.com/questions/43991306/angular-4-5-6-global-variables/43991457#43991457

@Injectable()
export class GlobalVars {
  public readonly customscim = 'urn:scim:schemas:custom:daasi:lui:2.0';

  private appInitializedSource = new Subject<boolean>();
  appInitialized$ = this.appInitializedSource.asObservable();

  public loginUser: User;
  private requestError: HttpErrorResponse;
  private stringError: string;

  public setAppInitialized() {
    this.appInitializedSource.next(true);
  }

  public setRequestError(error: HttpErrorResponse) {
    if (!this.requestError) {
      this.requestError = error;
    }
  }

  public retrieveRequestError(): HttpErrorResponse {
      const err = this.requestError;
      this.requestError = null;
      return err;
  }

  public setStringError(error: string) {
    if (!this.requestError) {
      this.stringError = error;
    }
  }

  public retrieveStringError(): string {
      const err = this.stringError;
      this.stringError = null;
      return err;
  }
}
