export class Permission {
  entry: string[];
  attributes: Map<string, string[]>;


  constructor(entry: object, attributes: object) {
    this.entry = Object.values(entry);
    this.attributes = new Map();
    for (const key of Object.keys(attributes)) {
      this.attributes.set(key, attributes[key]);
    }
  }

  public hasEntryPermission(op: string): boolean {
    return this.entry.indexOf(op) > -1;
  }

  public hasAttributePermission(attr: string, op: string): boolean {
    if (this.attributes.has(attr)) {
      return this.attributes.get(attr).indexOf(op) > -1;
    } else {
      return this.hasEntryPermission(op);
    }
  }

}
