import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomPrimeNGModule } from '../../custom-primeng.module';
import { HeaderComponent } from './components/header/header.component';
import { LoginlogoutbuttonComponent } from './components/loginlogoutbutton/loginlogoutbutton.component';
import { FooterComponent } from './components/footer/footer.component';



@NgModule({
  imports: [
    CommonModule,
    CustomPrimeNGModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoginlogoutbuttonComponent,
  ],
  declarations: [
    HeaderComponent,
    LoginlogoutbuttonComponent,
    FooterComponent,
  ],
  providers: [
  ]
})
export class CommonComponentsModule {}
