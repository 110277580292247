import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { UserPasswordService } from '../../services/user-password.service';
import { ResponseMessageService } from '../../services/response-message.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-passwordrecovery',
  templateUrl: './passwordrecovery.component.html',
  styleUrls: ['./passwordrecovery.component.scss']
})
export class PasswordrecoveryComponent implements OnInit, OnDestroy {
  email: string;
  msgs = [];

  private ngUnsubscribe = new Subject(); // to prevent memory leaks

  constructor(
    public userPasswordService: UserPasswordService,
    private responseMessageService: ResponseMessageService
  ) {}

  ngOnInit() {
  }

  submit() {
    this.userPasswordService.passwordrecovery(this.email)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((response) => {
      if (response && response['error']) {
        this.msgs.push({
          severity: 'error',
          summary: 'Error',
          detail: this.responseMessageService.getErrorText(response['error'])
        });
      } else {
        this.msgs.push({
          severity: 'success',
          summary: 'All good',
          detail: 'An e-mail with further instruction has been send to your e-mail address'
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
