import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { GlobalVars } from '../../global-vars';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnDestroy {
  appInitilizedSubscription: Subscription;

  constructor(
    private globalvars: GlobalVars,
    private router: Router,
    private oauthService: OAuthService,
  ) {
    if (this.globalvars.loginUser) {
      this.redirect();
    } else {
      this.appInitilizedSubscription = globalvars.appInitialized$.subscribe(
        status => {
          if (status) {
            this.redirect();
          }
      });
    }
  }

  private redirect() {
    // console.log('bin in redirect');
    if (!this.oauthService.hasValidAccessToken()) {
      this.router.navigate(['/login']);
    } else {
      // console.log(this.globalvars.loginUser);
      if (this.globalvars.loginUser && this.globalvars.loginUser.adminAccess) {
        console.log('redirect component - oninit - goto admin');
        this.router.navigate(['/admin']);
      } else {
        console.log('redirect component - oninit - goto profile');
        this.router.navigate(['/profile']);
      }
    }
  }

  ngOnDestroy() {
    if (this.appInitilizedSubscription) {
      this.appInitilizedSubscription.unsubscribe();
    }
  }

}
