import { Component, OnInit, Input, ElementRef, HostListener, ViewChild } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import {MenuItem} from 'primeng/primeng';
import { GlobalVars } from '../../../../global-vars';
import { Menu } from '../menu/menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  activeMenuId: string;
  @ViewChild('topbarmenu') topbarmenu: ElementRef;
  @Input() sideMenu: Menu;
  languages: MenuItem[];
  touchUser: boolean;

  constructor(
    public globalvars: GlobalVars,
    public oauthService: OAuthService
  ) { }

  ngOnInit() {
    this.languages = [
      {label: 'English', command: () => {
        this.selectLanguage('en');
      }},
      {label: 'German', command: () => {
        this.selectLanguage('de');
      }},
    ];
  }

  onMenuButtonClick(event: Event) {
    this.sideMenu.toggle();
  }

  onTopbarmenuClick(element: HTMLElement) {
    this.activeMenuId = (this.activeMenuId === element.id ? null : element.id);
  }

  onTopbarmenuMouseenter(element: HTMLElement) {
    if (!this.touchUser) {
      this.activeMenuId = (this.activeMenuId ? element.id : null);
    }
  }

  changeTheme(event: Event, theme: string) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
    themeLink.href = 'assets/themes/' + theme + '/theme.css';
    event.preventDefault();
  }

  selectLanguage(lang) {
    localStorage.setItem('language', lang);
    location.reload(true);
  }

  logout() {
    this.oauthService.logOut();
  }

  @HostListener('document:click', ['$event.target'])
  public onGlobalClick(targetElement) {
    if (!this.topbarmenu.nativeElement.contains(targetElement)) {
      this.activeMenuId = null;
    }
  }

  @HostListener('document:touchstart')
  public onGlobalTouch() {
    this.touchUser = true;
  }

}
