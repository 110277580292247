import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Route } from '@angular/compiler/src/core';
import { GlobalVars } from '../global-vars';

// Followed this tutorial: https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {

  constructor(private oauthService: OAuthService,
              private router: Router,
              private globalvars: GlobalVars) {}


  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // console.log('bin in canactivate oben');
      return this.oauthService
          .loadDiscoveryDocumentAndTryLogin()
          .then(_ => {
            if (!this.oauthService.hasValidAccessToken()) {
              this.router.navigate(['/login']);
              return false;
            }
            if (this.globalvars.loginUser) {
              if (route.data.adminAccess && !this.globalvars.loginUser.adminAccess) {
                this.router.navigate(['/profile']);
                return false;
              }
            } else {
              console.log('problem in auth-guard.ts: whoami has not been loaded');
              this.router.navigate(['']);
            }
            return true;
          });

  }


  canLoad(route: Route): Promise<boolean> {
    // console.log('bin in canload oben');
    return this.oauthService
    .loadDiscoveryDocumentAndTryLogin()
    .then(_ => {
      if (!this.oauthService.hasValidAccessToken()) {
        console.log('auth guard - canload - bin schuld');
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }

}
