export class Menu {
  menuActive: boolean;
  activeMenuId: string;

  constructor() {}

  toggle() {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }

}
