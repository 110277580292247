import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ResponseMessageService } from '../../services/response-message.service';
import { takeUntil } from 'rxjs/operators';
import { UserSelfregService } from '../../services/user-selfreg.service';

@Component({
  selector: 'app-selfreg',
  templateUrl: './selfreg.component.html',
  styleUrls: ['./selfreg.component.scss']
})
export class SelfregComponent implements OnInit, OnDestroy {
  signupForm = this.fb.group({
    name: this.fb.group({
      givenName: ['', Validators.required],
      familyName: ['', Validators.required]
    }),
    email: ['', [Validators.required, Validators.email]],
    agreesTo: this.fb.group({
      AGB: [false, this.trueValidator],
      Datenschutz: [false, this.trueValidator],
      Werbung: [false]
    })
  });

  msgs = [];

  private ngUnsubscribe = new Subject(); // to prevent memory leaks

  constructor(
    private fb: FormBuilder,
    public userSelfregService: UserSelfregService,
    private responseMessageService: ResponseMessageService
  ) {}

  ngOnInit() {
  }

  trueValidator(control: AbstractControl) {
    if (control.value === true || control.value === 'true') {
      return null;
    }
    return { notSetToTrue: true };
  }

  onSubmit() {
    if (this.signupForm.dirty && this.signupForm.valid) {
      this.userSelfregService.selfreg(JSON.stringify(this.signupForm.value))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response && response['error']) {
          this.msgs.push({
            severity: 'error',
            summary: 'Error',
            detail: this.responseMessageService.getErrorText(response['error'])
          });
        } else {
          this.msgs.push({
            severity: 'success',
            summary: 'All good',
            detail: 'An e-mail with further instruction has been send to your e-mail address'
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
