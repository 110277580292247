import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvConfigService } from '../services/env-config.service';
import { Router } from '@angular/router';
import { GlobalVars } from '../global-vars';


@Injectable()
export class OAuthInterceptor implements HttpInterceptor, ErrorHandler {

    constructor(
      private authStorage: OAuthStorage,
      private envConfig: EnvConfigService,
      private globalvars: GlobalVars,
      private router: Router
    ) { }

    private checkUrl(url: string): boolean {

      if (!url.startsWith('http')) {
        return false;
      }

      if (url.startsWith(this.envConfig.getConfig().lui2backend.baseUri)) {
        return true;
      }

      return false;
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const url = request.url.toLowerCase();
        // console.log('interceptor: ', url);

        if (!this.checkUrl(url)) {
          return next.handle(request).pipe(
            catchError(err => this.handleError(err))
          );
        }
        // console.log('interceptor: passed the ifs');

        const token = this.authStorage.getItem('access_token');

        const req = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token)
        });

        return next.handle(req).pipe(
          catchError(err => this.handleError(err))
        );

    }

    handleError(error: HttpResponse<any>) {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 0) {
          this.globalvars.setStringError('Authentiction or LUI Backend not reachable');
        } else {
          this.globalvars.setRequestError(error);
        }
        console.log('Error: ', error);
        this.router.navigate(['/error']);
        return EMPTY; // kein weiteres error handling
      } else {
        return Observable.throw(error); // error nach oben durchreichen
      }
    }

}
