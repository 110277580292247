import {Permission} from './permission';
export class User {
  id: string; // dn
  scimdata: object;
  permission: Permission;
  adminAccess: boolean;


  constructor() {}

}
