import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class NotLoggedinGuardService implements CanActivate, CanActivateChild {

  constructor(private oauthService: OAuthService,
              private router: Router) {}


  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    console.log('bin in not-loggedin-guard canactivate oben');
      return this.oauthService
          .loadDiscoveryDocumentAndTryLogin()
          .then(_ => {
            if (this.oauthService.hasValidAccessToken()) {
              this.router.navigate(['/profile']);
              return false;
            }
            return true;
          });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }

}
