import { Component, OnInit } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalVars } from '../../global-vars';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css']
})

export class ErrorpageComponent implements OnInit {
  requestError: HttpErrorResponse;
  stringError: string;

  constructor(
    private globalvars: GlobalVars,
    public sanitizer: DomSanitizer
  ) {
    this.requestError = this.globalvars.retrieveRequestError();
    this.stringError = this.globalvars.retrieveStringError();
  }

  ngOnInit() {
  }

}
