import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserPasswordService } from '../../services/user-password.service';
import { ResponseMessageService } from '../../services/response-message.service';
import { takeUntil, map, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.scss']
})
export class SetpasswordComponent implements OnInit, OnDestroy {
  tokenValid: boolean;
  token: string;

  newpassword = '';
  newpasswordconfirm = '';
  msgs = [];

  private ngUnsubscribe = new Subject(); // to prevent memory leaks

  constructor(
    private route: ActivatedRoute,
    public userPasswordService: UserPasswordService,
    private responseMessageService: ResponseMessageService
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        map(params => params.token),
        switchMap(token => {
          this.token = token;
          const json = `{"token": "${this.token}"}`;
          return this.userPasswordService.setpasswordwithtoken(json);
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(response => {
        if (response && response['error']) {
          this.tokenValid = false;
          this.msgs.push({
            severity: 'error',
            summary: 'Error validating token',
            detail: this.responseMessageService.getErrorText(response['error'])
          });
        } else {
          this.tokenValid = true;
        }
      });
  }

  submit() {

    /*** Validation ***/
    this.msgs = [];

    // check if all fields contain value
    if (this.newpassword === '' || this.newpasswordconfirm === '') {
      this.msgs.push({severity: 'error', summary: 'Invalid input', detail: 'Please fill in all form fields'});
      return;
    }

    // check if password and confirm password is the same
    if (this.newpassword !== this.newpasswordconfirm) {
      this.msgs.push({
        severity: 'error',
        summary: 'Invalid input',
        detail: 'Your new password doesn\'t match with the confirmation of the new password'
      });
      return;
    }

    /*** SAVING ***/
    const json = `
    {
      "token": "${this.token}",
      "password": "${this.newpassword}"
    }
    `;

    this.userPasswordService.setpasswordwithtoken(json)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response && response['error']) {
          this.msgs.push({
            severity: 'error',
            summary: 'Error setting password',
            detail: this.responseMessageService.getErrorText(response['error'])
          });
        } else {
          this.tokenValid = false;
          this.msgs.push({
            severity: 'success',
            summary: 'All good',
            detail: 'Your password has been set'
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
