import { NgModule } from '@angular/core';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CardModule } from 'primeng/card';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {DataGridModule} from 'primeng/datagrid';
import {DataListModule} from 'primeng/datalist';
import {DataScrollerModule} from 'primeng/datascroller';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {GrowlModule} from 'primeng/growl';
import {InputMaskModule} from 'primeng/inputmask';
import {PanelModule} from 'primeng/panel';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TabMenuModule} from 'primeng/tabmenu';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import {TabViewModule} from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TreeModule } from 'primeng/tree';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {FileUploadModule} from 'primeng/fileupload';
import {MenuItem} from 'primeng/api';

@NgModule({
  imports: [
    TieredMenuModule,
    TabMenuModule,
    BreadcrumbModule,
    TreeModule,
    DropdownModule,
    ButtonModule,
    DataScrollerModule,
    DataListModule,
    DataGridModule,
    PanelModule,
    PanelMenuModule,
    CardModule,
    GrowlModule,
    TabViewModule,
    TableModule,
    InputTextModule,
    PasswordModule,
    AutoCompleteModule,
    CheckboxModule,
    FieldsetModule,
    InputMaskModule,
    SplitButtonModule,
    MessagesModule,
    MessageModule,
    FileUploadModule,
  ],
  exports: [
    TieredMenuModule,
    TabMenuModule,
    BreadcrumbModule,
    TreeModule,
    DropdownModule,
    ButtonModule,
    DataScrollerModule,
    DataListModule,
    DataGridModule,
    PanelModule,
    PanelMenuModule,
    CardModule,
    GrowlModule,
    TabViewModule,
    TableModule,
    InputTextModule,
    PasswordModule,
    AutoCompleteModule,
    CheckboxModule,
    FieldsetModule,
    InputMaskModule,
    SplitButtonModule,
    MessagesModule,
    MessageModule,
    FileUploadModule,
  ],
})
export class CustomPrimeNGModule { }
