import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {LoginComponent} from './components/login/login.component';

import {Code404Component} from './components/code404/code404.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { NotLoggedinGuardService as NotLoggedinGuard } from './services/not-loggedin-guard.service';
import { RedirectComponent } from './components/redirect/redirect.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { PasswordrecoveryComponent } from './components/passwordrecovery/passwordrecovery.component';
import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { SelfregComponent } from './components/selfreg/selfreg.component';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: RedirectComponent
  },
  {
    path: 'profile',
    loadChildren: 'app/modules/selfservice-module/selfservice.module#SelfserviceModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: 'app/modules/admin-module/admin.module#AdminModule',
    canLoad: [AuthGuard],
    data: {
      adminAccess: true
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotLoggedinGuard]
  },
  {
    path: 'passwordrecovery',
    component: PasswordrecoveryComponent,
    canActivate: [NotLoggedinGuard]
  },
  {
    path: 'setpassword/:token',
    component: SetpasswordComponent,
    canActivate: [NotLoggedinGuard]
  },
  {
    path: 'selfreg',
    component: SelfregComponent,
    canActivate: [NotLoggedinGuard]
  },
  {
    path: 'error',
    component: ErrorpageComponent
  },
  {
    path: 'spinner',
    component: SpinnerComponent
  },
  { path: '**', component: Code404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        // enableTracing: true, // <-- debugging purposes only
        // initialNavigation: false,
      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
  ]
})
export class AppRoutingModule { }
