
import {throwError as observableThrowError, Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';


import {User} from '../class/user';
import {Permission} from '../class/permission';
import { EnvConfigService } from './env-config.service';
import { GlobalVars } from '../global-vars';

@Injectable()
export class UserService {

  private userEndpointUrl: string;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });


  constructor(
    private http: HttpClient,
    private envConfig: EnvConfigService,
    private globalvars: GlobalVars
  ) {
    this.userEndpointUrl = this.envConfig.getConfig().lui2backend.baseUri + '/v2/User/';
}

  // MULTIPLE USERS
  // ==============

  // Get users form backend
  getUsers(): Observable<object> {
    const usersUrl = this.userEndpointUrl + '?filter=All';
    return this.http.get(usersUrl, {headers: this.headers}).pipe(
      catchError(this.handleError)
    );
  }

  getUsersOfSub(sub: string): Observable<object> {
    return this.http.get(this.userEndpointUrl + sub + '/', {headers: this.headers}).pipe(
      catchError(this.handleError)
    );
  }

  getPermissions(users: User[]): Observable<object> {
    const permissionUrl = this.envConfig.getConfig().lui2backend.baseUri + '/checkfunction/multi/';
    let json = '{"Resources": ["';
    for (const user of users) {
      json = json + user.id + '" , "';
    }
    json = json.substring(0, json.length - 3);
    json = json + ']}';
    return this.http.post(permissionUrl, json, {headers: this.headers}).pipe(
      catchError(this.handleError)
    );
  }

  // convert json to users Array
  jsonToUsers(json: any): User[] {
    const customscim = this.globalvars.customscim;
    const users = [];
    for (const entry of json.Resources) {
      const user = new User();
      user.id = entry.id;
      user.scimdata = entry;
      if (entry['meta'][customscim] &&
          entry['meta'][customscim]['operations'] &&
          entry['meta'][customscim]['operations']['entry']) {
        user.permission = new Permission(
          entry['meta'][customscim]['operations']['entry'],
          entry['meta'][customscim]['operations']['attributes']
        );
      } else {
        user.permission = new Permission([], []);
      }
      users.push(user);
    }
    return users;
  }


  // Single USER
  // ==============


  // Get user by ID form backend
  getUser(userId: string): Observable<object> {
    const url = this.userEndpointUrl + userId + '/';
    return this.http.get(url, {headers: this.headers}).pipe(
      catchError(this.handleError)
    );
  }

  // get info about the loggedIn user
  getIdentity(): Observable<object> {
    const whoAmIUrl = this.envConfig.getConfig().lui2backend.baseUri + '/whoami/';

    return this.http.get(whoAmIUrl, {headers: this.headers}).pipe(
        catchError(this.handleError)
      );
  }

  jsonToUser(responseData: object): User {
    const customscim = this.globalvars.customscim;

    const user = new User();

    user.id = responseData['id'];
    user.scimdata = responseData;

    if (responseData['meta'][customscim] &&
        responseData['meta'][customscim]['operations'] &&
        responseData['meta'][customscim]['operations']['entry']) {
      user.permission = new Permission(
        responseData['meta'][customscim]['operations']['entry'],
        responseData['meta'][customscim]['operations']['attributes']
      );
    } else {
      user.permission = new Permission([], []);
    }

    user.adminAccess = responseData[customscim]['adminAccess'];

    return user;
  }


  // Update User
  update(userdn: string, json: string): Observable<any> {
    const url = this.userEndpointUrl + userdn + '/';
    return this.http
      .patch(url, json, {headers: this.headers}).pipe(
      catchError(this.handleError)
    );
  }

  // Delete User
  delete(userdn: string): Observable<any> {
    const url = this.userEndpointUrl + userdn + '/';
    return this.http.delete(url, {headers: this.headers}).pipe(
      catchError(this.handleError)
    );
  }

  createUser(user: User): Observable<any> {
    const json = JSON.stringify(user.scimdata);
    return this.http
      .post(this.userEndpointUrl, json, {headers: this.headers}).pipe(
      catchError(this.handleError)
    );
  }


  // handle server errors
  protected handleError(error: HttpErrorResponse) {
    console.log('bin im userService error handler');
    return observableThrowError(error || 'Server Error');
  }
}
