import { Injectable } from '@angular/core';

@Injectable()
export class ResponseMessageService {
  // Translate the messages received from the BE

  errorMap: Map<string, string> = new Map([
    ['notimplemented', 'This function is not yet implemented'],
    ['mailnotfound', 'The e-mail address could not be found in the system'],
    ['tokennotfound', 'Token invalid or expired'],
    ['mailalreadyexists', 'There already is a user account with the given e-mail address']
  ]);

  constructor() {
  }

  public getErrorText(error: string): string {
    const errorText = this.errorMap.get(error);
    if (errorText) {
      return errorText;
    } else {
      return 'undefined error: ' + error;
    }
  }

}
