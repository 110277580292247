import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Code404Component } from './components/code404/code404.component';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { LoginComponent } from './components/login/login.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CustomPrimeNGModule } from './custom-primeng.module';
import { GlobalVars } from './global-vars';
import { OAuthInterceptor } from './http-interceptors/oauth-interceptor';
import { CommonComponentsModule } from './modules/common-components-module/common-components.module';
import { AuthConfigService } from './services/auth-config.service';
import { AuthGuardService } from './services/auth-guard.service';
import { NotLoggedinGuardService } from './services/not-loggedin-guard.service';
import { EnvConfigService } from './services/env-config.service';
import { UserService } from './services/user.service';
import { UserPasswordService } from './services/user-password.service';
import { UserSelfregService } from './services/user-selfreg.service';
import { ResponseMessageService } from './services/response-message.service';
import { PasswordrecoveryComponent } from './components/passwordrecovery/passwordrecovery.component';
import { SelfregComponent } from './components/selfreg/selfreg.component';
import { SetpasswordComponent } from './components/setpassword/setpassword.component';


const appInitializerFn = (appConfig: EnvConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};


@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    SpinnerComponent,
    LoginComponent,
    Code404Component,
    Code404Component,
    ErrorpageComponent,
    PasswordrecoveryComponent,
    SelfregComponent,
    SetpasswordComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    CommonComponentsModule,
    CustomPrimeNGModule,
  ],
  providers: [
    AuthGuardService,
    NotLoggedinGuardService,
    UserService,
    UserPasswordService,
    UserSelfregService,
    EnvConfigService,
    AuthConfigService,
    GlobalVars,
    ResponseMessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [EnvConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true,
      deps: [
        OAuthStorage,
        EnvConfigService,
        GlobalVars,
        Router
      ]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
