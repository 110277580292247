import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-loginlogoutbutton',
  templateUrl: './loginlogoutbutton.component.html',
  styleUrls: ['./loginlogoutbutton.component.css']
})
export class LoginlogoutbuttonComponent implements OnInit {

  constructor(public oauthService: OAuthService) {
  }


  public login() {
    this.oauthService.initImplicitFlow();
  }

  public logoff() {
    this.oauthService.logOut();
  }

  ngOnInit() {
  }

}
