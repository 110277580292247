
import {throwError as observableThrowError, Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';


import { EnvConfigService } from './env-config.service';

@Injectable()
export class UserSelfregService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
    private envConfig: EnvConfigService
  ) {}


  // Change Password of loggedin User
  selfreg(json: string): Observable<any> {
    const url = this.envConfig.getConfig().customerApp.baseUri + '/selfreg/';
    return this.http
      .post(url, json, {headers: this.headers}).pipe(
      catchError(this.handleError)
    );
  }


  // handle server errors
  protected handleError(error: HttpErrorResponse) {
    console.log('bin im userService error handler');
    return observableThrowError(error || 'Server Error');
  }
}
