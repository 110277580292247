import { TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID, MissingTranslationStrategy } from '@angular/core';
import { CompilerConfig } from '@angular/compiler';
import { TRANSLATION_DE } from '../i18n/messages.de';
import {_document} from '@angular/platform-browser/src/browser';


export function getTranslationProviders() {

  // Get the locale id from the global
  // const locale = CONST_LANGUAGE;
  const locale = localStorage.getItem('language');

  // No locale or U.S. English: no translation providers
  if (locale === 'de') {
    return [
      { provide: TRANSLATIONS, useValue: TRANSLATION_DE },
      { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
      { provide: LOCALE_ID, useValue: locale },
    ];
  }
  return [];
}

